import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`
`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`

function QuickServeRestaurantsPage() {
  return (
    <Layout pageName="industry">
       <SEO
        title="Leading edge business technology solutions for quick service restaurants"
        description="We excel in industry-specific technology solutions for quick-serve restaurants across the USA. Contact us for an end-to-end POS, digital signage, access control, and other technology solutions."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Quick Serve Restaurants</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for the Quick Serve Restaurant Industry</GroceryTitle>
              <p>Industry-specific technology for quick service restaurants (or QSRs) hasn’t grown by leaps and bounds over the course of the pandemic. What we are seeing, though, is more pressure for restaurants to adopt existing technologies in order to help elevate the customer experience. QSR patrons have come to expect conveniences and services like online ordering, contactless payment, outdoor iPad order takers to help shorten long lines, and third-party delivery options. QSRs who fail to implement these technologies will do so at their own peril.</p>
              <p>But whether you operate a single independent location or manage a chain of franchise locations, there are also many relevant business technology solutions that can improve your business for your customers, your employees, and for you. And <strong>WYN Technologies</strong> is happy to come alongside to deliver the essential technology solutions your QSR needs!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Essential Services We Offer to QSR Businesses</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling, Including Fiber Network Options</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having the right structured cabling network is crucial to the success of any modern business or organization, and that most certainly includes quick service restaurants. Need some remediation of your legacy data wiring? We can take care of that. Plus, <strong>WYN</strong> actually specializes in the design and installation of new structured cable networks, racking, and data closets for all commercial applications.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having a good WiFi network in place is crucial for the needs of modern QSR operations. Not only is much of your equipment connected to your business wireless network, but your QSR patrons have come to expect reliable WiFi availability whenever they visit, too. Whether you have an existing network which needs some troubleshooting and upgrading, or whether you need a new wireless network installed, we’ve got you covered at <strong>WYN Technologies</strong>.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Drive-Thru Ordering Technology Installations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>If your business is like that of many QSRs, the drive-thru is really one of the essential lifebloods of your business. Before the pandemic ever arrived,customers appreciated an effective, efficient drive-thru service, and now they’ve truly come to truly expect it. If it’s time to ramp up your quality of drive-thru service, <strong>WYN</strong> has the technology solutions you need. Outdoor digital signs, interactive touchscreens, and modern intercom systems can help you get where you need to be. Need some help designing your perfect drive-thru solution? We’re here to serve!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>First impressions decide whether a customer will stay with you; last impressions decide whether a customer will ever come back. And when it comes to QSRs, the checkout experience is the ultimate last impression. Having the right point-of-sale system makes all the difference in getting diners in and out efficiently, and makes the experience more pleasant for customers and employees alike. <strong>WYN Technologies</strong> provides and installs the best touchscreen POS registers and integrated systems which make that lasting final impression to be as positive as possible!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Digital Signage Installation</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Digital signage has many great applications for QSR establishments. Outdoor digital signage can help to draw folks to your business in the first place. Indoor digital signage is great for your menus and other special displays. And interactive touchscreen signs are preferred for engaging your customers both inside the store, as well as at the drive-thru. <strong>WYN</strong> can install the right digital signage for each of these needs, and more!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Technology Project Management for Moves, Adds, & Changes (MAC Services </GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Need a one-off installation of some specialized business technology? We can handle that. But we can also serve as your technology installation consultant for new locations and site remodels, too. We can de-install any legacy equipment which may need moving or replacing, and we can help you plan for new equipment installations as well. Whatever the particulars may be for your unique needs, <strong>WYN Technologies</strong> knows how to meet your project deadlines, and we’re committed to exceeding your expectations!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Wish you had some good business technology IT support for your quick service restaurant? That’s available to you by partnering with <strong>WYN Technologies</strong>! We’re here whenever you may need us, and we’re also happy to provide service-after-the-sale contracts to help keep your equipment running smoothly in the days ahead. You’ve got enough to worry about when it comes to running your restaurant; let us help to prevent any unnecessary technology headaches!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Depot Repair Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Here’s something else that sets <strong>WYN</strong> apart, too. We have a complete staging facility (complete with a secure data closet, video surveillance, and protected access control) for getting your new equipment set up and ready to go, before the date of installation ever arrives. Got some older business technology equipment which may need replacing? You can opt to take advantage of our depot repair services, and choose an effective refurbished equipment solution which carries a more affordable price tag!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>VoIP Services for Quick Service Restaurants</h2>
                  <p>Having a good business phone and communications system in place is really essential for QSR enterprises. Older analog systems or local PBX systems really won’t cut it anymore. It’s time for you to upgrade to a VoIP-based phone system. Why? Honestly, there are many reasons.</p>
                  <p>For starters, you’ll be able to enjoy many features with VoIP which simply aren’t available with older technologies. And here’s one of the most compelling reasons to switch – VoIP phone services are typically much cheaper to operate than other standard monthly business phone plans. With more than 20 VoIP provider options to offer, <strong>WYN Technologies</strong> is certain to be able to help you find your perfect VoIP solution. Connect with us today, and let’s talk about how we can deliver your best QSR business communications solution!</p>
               </MarkdownContent>
               <MarkdownContent>
                    <h2>Choose WYN Technologies for All Your QSR Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At <strong>WYN</strong>, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility – before we ever come out to install your new equipment.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p><strong>WYN Technologies</strong> only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p><strong>WYN</strong> is ultimately here to help your business succeed. We understand how corporate finances work, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, <strong>WYN</strong> is committed to be here for you for the long run. We actually answer the phone whenever you call, and we’ll respond promptly to your needs in a timely manner, too.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for your independent QSR location, or for multiple franchises you manage? <strong>WYN Technologies</strong> is happy to provide you with a free initial site evaluation and consultation. Got more questions? Ready to get the conversation started? Connect with us now, and let’s make a plan for achieving the best solutions for your business by leveraging the right business technology to get you where you want to go!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
       </Section>
    </Layout>
  )
}
export default QuickServeRestaurantsPage
